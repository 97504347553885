(function ($) {
	$(document).on("pageinit",function(){
		$.mobile.ajaxEnabled = false;

		var iframes = $('#content iframe');
		if(iframes.length > 0){
			initIframes();
		}

		if($('#campaign-slider').length > 0){
			initSlider();
		}

		try{
			if($realImageWidth!='' || $realImageWidth != undefined){
				initMap();
			}
		}catch(e){}

		$("#header .search-but").click(function(){
			$("#header .search-box").slideToggle(0);
			return false;
		});

		var qs = {};
		if(location.href.split('?').length>1){
			location.href.split('?')[1].split('&').forEach(function(i){
				qs[i.split('=')[0]]=i.split('=')[1];
			});
		}

		if(qs.search != ''){
			$(".search-input").val(qs.search);
		}
		/*------------------ input hint ----------------*/
		$(".search-input").focus(function(){
			var hint = $(this).attr("data-hint");
			var val = $(this).val();
			if(val == hint) $(this).val("");
		});
		$(".search-input").blur(function(){
			var hint = $(this).attr("data-hint");
			var val = $(this).val();
			if(val == '') $(this).val(hint);
		});
		$(".search-input").keypress(function(e){
			if(e.which==13){
				var $url = "/Global/Search.aspx?search="+$(this).val();
				window.location.href = $url;
				return false;
			}
		});

		/*------------------ radio and ckeck buttons ------------------------*/
		$(".radiogroup input").change(function(){
			var radiobuttons = $(this).parents(".radiogroup").find(".radiobut"),
				activeBut = $(this).parents(".row.radio").find(".radiobut");
			radiobuttons.removeClass("checked");
			activeBut.addClass("checked");
		});
		$(".checkbox input").change(function(){
			var activeBut = $(this).parents(".row.checkbox").find(".checkboxBut");
			if(!activeBut.hasClass('checked')){
				activeBut.addClass("checked");
			}else{
				activeBut.removeClass("checked");
			}
		});

		/*------------------- main menu -----------------*/
		$("#main-menu .level-menu a.switcher").click(function(){
			if($(this).hasClass("sub-menu-off")){
				$(this).removeClass("sub-menu-off").addClass("sub-menu-on");
				$(this).next(".sub-menu").stop().slideDown(400);
			}else if($(this).hasClass("sub-menu-on")){
				$(this).removeClass("sub-menu-on").addClass("sub-menu-off");
				$(this).next(".sub-menu").stop().slideUp(400);
			}
			return false;
		});

		/*----------------- donwloads spot ---------------*/
		$(".spots-holder .downloads .switcher").click(function(){
			if($(this).hasClass("list-off")){
				$(this).removeClass("list-off").addClass("list-on");
				$(this).nextAll(".list").stop().slideDown(400);
			}else if($(this).hasClass("list-on")){
				$(this).removeClass("list-on").addClass("list-off");
				$(this).nextAll(".list").stop().slideUp(400);
			}
			return false;
		});

		/*---------------- latest news spot ----------------*/
		$('.latest-news .more-news-but a').click(function(){
			$('.latest-news .news-hidden').slideDown("300").removeClass('news-hidden');
			$('html,body').animate({scrollTop: $('.latest-news .first-hidden').offset().top});
			$('.latest-news .more-news-but').hide();
		});



		/*--------------- campaign slider ----------------*/
		var activeIndex = 0;
		function initSlider() {
			correctSliderDimentions();
			var rotationSpeed = parseInt($rotationSpeed)*1000;
			//alert(rotationSpeed);

			var slider = $("#campaign-slider");
			var slideWidth;
			var slideCount = slider.find(".slider-item").length;

			$(window).resize(function(){
				slideWidth = slider.find(".slider-item").width();
				slider.stop().css({
					left: (-1)*(activeIndex)*slideWidth+"px"
				});
				correctSliderDimentions();
			});

			// auto rotate
			var autoRotate = function(){
				if(autoRotate==false) return;
				slide(1);
			}
			var autoRotateId = setInterval(autoRotate, rotationSpeed);
			var rotationDelay;


			// events bindings
			$(".slideshow-holder").on("swiperight",function(){
				$.event.special.swipe.scrollSupressionThreshold = 10;
				$.event.special.swipe.horizontalDistanceThreshold = 50;
				$.event.special.swipe.verticalDistanceThreshold = 75;
				$.event.special.swipe.durationThreshold = 1000;
				slide(0);
			});

			$(".slideshow-holder").on("swipeleft",function(){
				slide(1);
			});

			$(".slideshow-holder .button-wrapper").css({opacity: "0"});
			$(".slideshow-holder").on("click",function(e){
				var point = e.clientX;

				slideWidth = slider.find(".slider-item").width();
				if(point < 90){
					$(".slideshow-holder .button-wrapper.prev").animate({opacity: "0.5"},350,function(){$(this).animate({opacity: "0"},150);});
					slide(0);
				}
				else if(point>slideWidth-90 && point<slideWidth){
					$(".slideshow-holder .button-wrapper.next").animate({opacity: "0.5"},350,function(){$(this).animate({opacity: "0"},150);});
					slide(1);
				}
			});

			function slide(direction){
				autoRotateId = clearInterval(autoRotateId);
				rotationDelay = clearTimeout(rotationDelay);
				slideWidth = slider.find(".slider-item").width();

				if(direction == 1){
					if(activeIndex==slideCount-1){
						slider.animate({
							left: "0px"
						},"300");
						activeIndex = 0;
					}else{
						slider.animate({
							left: (-1)*(++activeIndex)*slideWidth+"px"
						},"300");
					}
				}else if(direction == 0){
					if(activeIndex == 0){
						slider.animate({
							left: (-1)*(slideCount-1)*slideWidth+"px"
						},"300");
						activeIndex = slideCount-1;
					}else{
						slider.animate({
							left: (-1)*(--activeIndex)*slideWidth+"px"
						},"300");
					}
				}

				rotationDelay = setTimeout(function(){
					autoRotateId = setInterval(autoRotate,rotationSpeed);
				},2000);
			}
		}

		function correctSliderDimentions(){
			var slider = $("#campaign-slider");

			var curSlideWidth = slider.find(".slider-item").width();
			var curOffset = parseInt(slider.css("left"));
			var curSlide = Math.abs(curOffset/curSlideWidth);
			var newSlideWidth = $(window).width();

			// slider.find(".slider-item").each(function(){
			// 	$(this).width(newSlideWidth);
			// 	$(this).find("img").width(newSlideWidth);
			// });

			var slideCount = slider.find(".slider-item").length;
			var newOffset = -1*Math.abs(curSlide*newSlideWidth);
			slider.width(newSlideWidth*slideCount).css({
				left: (newOffset)+"px"
			});

			// $("#mainContent").width(newSlideWidth);
		}



		/*------------------- map page ------------------*/
		function initMap() {
			var $map = $('.map');
			var mapImage = $map.find('.mapImage');
			var width = mapImage.width();
			var coeff = width/$realImageWidth;

			correctPosition($map,coeff);

			//console.log(navigator.userAgent);
			$('.map .location').removeClass('ui-link').click(function(){
				/*if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ){*/
				//animated borders
				$('.map .location .link').each(function(){
					$(this).find('.border').eq(0).stop().animate({
						width: 26, height: 22, top: '2px', left: 0
					},250);
					$(this).find('.border').eq(1).stop().animate({
						width: 22, height: 26, top: 0, left: '2px'
					},250);
				});
				$(this).addClass('active');
				$(this).find('.border').eq(0).stop().animate({
					width: 54, height: 44, top: -8, left: -13
				}, 250);
				$(this).find('.border').eq(1).stop().animate({
					width: 44, height: 54, top: -13, left: -8
				},250);

				$('.map .locationInfo').hide();
				var localInfo = $(this).next('.locationInfo').eq(0).html();
				$('.map .infoPopup .popupContent').html('').append(localInfo).parent('.infoPopup').show();
				return false;
				/*}*/
			});
			$('.map .infoPopup .closeBut').click(function(){
				$(this).parent('.infoPopup').fadeOut('250');
				var activeLink = $('.map .location.active');
				activeLink.find('.border').eq(0).stop().animate({
					width: 26, height: 22, top: '2px', left: 0
				},250);
				activeLink.find('.border').eq(1).stop().animate({
					width: 22, height: 26, top: 0, left: '2px'
				},250);
				activeLink.removeClass('active');
				return false;
			});

			$(window).resize(function(){
				width = mapImage.width();
				coeff = width/$realImageWidth;

				correctPosition($map,coeff);
			});

			$('#mainform').addClass('pageWithMap');
		}

		function correctPosition(map,coeff){
			map.find('.location').each(function(){
				var newTop = $(this).attr('data-top')*coeff;
				var newLeft = $(this).attr('data-left')*coeff;
				$(this).css({
					'top': newTop+'px',
					'left': newLeft+'px'
				});
			});
			map.find('.infoPopup').each(function(){
				var margin =  -$(this).width()/2+'px';
				$(this).css({
					'margin-left': margin
				});
			});
		}


		function initIframes(){
			iframes.each(function(){
				var coef = $(this).attr('width')/$(this).attr('height');
				$(this).height($(this).width()/coef).attr('data-coef',coef);
			});
			$(window).resize(function(){
				iframes.each(function(){
					var coef = $(this).attr('data-coef');
					$(this).height($(this).width()/coef);
				});
			});
		}
		
		if (!$('#LoginExtranet1_m_panelShowLogin #LoginExtranet1_m_txtName').length) {
			$('#footer').before($('#LoginExtranet1_m_panelShowLogin'));
		};
	});
})(jQuery);


jQuery(window).load(function () {
    var $ = jQuery;
    //Replace social icons for sharethis plugin
    var shareThisIsActive = false;
    replaceSocialIcons();

    function replaceSocialIcons() {

        if ($("ul.sites [st_processed]").length) {
            shareThisIsActive = true;
            replaceIcons();
        } else {
            setTimeout(function () {
                replaceSocialIcons();
            }, 500);
        }

        function replaceIcons() {
            var anyChicklets = $("ul.sites").find(".chicklets");
            //Check if any of these icons are displayed. Some times there are only email, share and print available
            if (anyChicklets.length >= 1) {
                $('.st_facebook').find(".chicklets").removeClass("facebook");
                $('.st_facebook .chicklets').addClass("replace-facebook");

                $('.st_twitter').find(".chicklets").removeClass("twitter");
                $('.st_twitter .chicklets').addClass("replace-twitter");

                $('.st_linkedin').find(".chicklets").removeClass("linkedin");
                $('.st_linkedin .chicklets').addClass("replace-linkedin");

                $('.st_googleplus').find(".chicklets").removeClass("googleplus");
                $('.st_googleplus .chicklets').addClass("replace-googleplus");

                $('.st_viadeo').find(".chicklets").removeClass("viadeo");
                $('.st_viadeo .chicklets').addClass("replace-viadeo");
            }
            $('.st_email_large').find(".stLarge").attr("background-image", "none").css("visibility", "visible");
            $('.st_email_large .stLarge').addClass("replace-email");

            $('.st_sharethis_large').find(".stLarge").attr("background-image", "none").css("visibility", "visible");
            $('.st_sharethis_large .stLarge').addClass("replace-sharethis");

            $('.st_print_large').find(".stLarge").attr("background-image", "none").css("visibility", "visible");
            $('.st_print_large .stLarge').addClass("replace-print");

            jQuery.each($('[class^="st_"]'), function (index, item) {
                //Injecting svgs
                var itemClass = $(item).attr('class').split('_')[1];
                $(item).find('span span').load('/UI/img/socialConnect/' + itemClass + '.svg', function () {


                    $('svg[icopal]').each(function (index, item) {
                        var element = 'path';
                        var property = 'fill';

                        if ($(item).attr('email') === 'yes') {
                            element = 'rect';
                        }

                        if ($(item).attr('separator') === 'yes') {
                            element = 'rect';
                            property = 'fill';
                            //set theme for seperator
                            var theme = $(item).data("theme");
                        }
                        else {
                            //set mobile-theme for icons
                            //this attr is set in Content.xslt
                            theme = $('#content').data('mobile-theme');
                        }

                        switch (theme) {
                            case 'wide':
                                $(item).find(element).attr(property, '#30afe3');
                                break;
                            case 'decra':
                                $(item).find(element).attr(property, '#81735d');
                                break;

                            case 'monarflex':
                            case 'orange':
                                $(item).find(element).attr(property, '#ec7404');
                                break;

                            case 'siplast':
                                $(item).find(element).attr(property, '#00338e');
                                break;

                            case 'synthetics':
                                $(item).find(element).attr(property, '#d20057');
                                break;

                            case 'imaroofer':
                                $(item).find(element).attr(property, '#B93531');
                                break;

                            case 'wijo':
                                $(item).find(element).attr(property, '#00338e');
                                break;

                            case 'esha':
                                $(item).find(element).attr(property, '#59B223');
                                break;

                            case 'dark-blue':
                            case 'theme-dark-blue':
                                $(item).find(element).attr(property, '#004264');
                                break;

                            case 'brown':
                                $(item).find(element).attr(property, '#81735d');
                                break;

                            case 'dark-green':
                                $(item).find(element).attr(property, '#587c16');
                                break;

                            case 'pink':
                                $(item).find(element).attr(property, '#db0962');
                                break;

                            case 'red':
                                $(item).find(element).attr(property, '#e6372d');
                                break;

                            case 'vedag':
                                $(item).find(element).attr(property, '#0061af');
                                break;

                            case '':
                                $(item).find(element).attr(property, '#00aaef');
                                break;
                        }
                    });
                });
            });
        }
    }
});