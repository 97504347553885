(function ($) {
	$(document).ready(function () {
		$('.subAccordionList').click(function (event) {
			event.stopPropagation();
		}).hide();

		$('.accordionListItem').click(function () {
			$('.accordionListItem').not($(this)).removeClass('active');
			$(this).toggleClass('active');

			var selfClick = $(this).find('.subAccordionList:first').is(':visible');
			if (!selfClick) {
				$(this)
					.parent()
					.find('> .accordionListItem .subAccordionList:visible')
					.slideToggle();
			}

			$(this)
				.find('.subAccordionList:first')
				.stop(true, true)
				.slideToggle();
		});
	});
})(jQuery);



