(function ($) {
	var cc, accepted=false;
	$(document).ready(function(){
		cc = $('.cookiesContainer');
		cc.find('.acceptButton').click(function(){
			acceptCookiePolicy();
		});
		showCookiePolicy();
		if(!accepted)$.cookies.set("lastVisitedPage", document.location.href, { expiresAt: new Date(2020, 1, 1) });
	});

	function showCookiePolicy(){
		var ac =  $.cookies.get("acceptCookies");
		var lvp = $.cookies.get("lastVisitedPage");
		if(ac != true && lvp == document.location.href || ac != true && lvp == null){cc.show();}
	}

	function acceptCookiePolicy(){
		cc.fadeOut(300);
		$.cookies.set("acceptCookies", true, { expiresAt: new Date(2020, 1, 1) });
		$.cookies.del("lastVisitedPage");
		accepted = true;
	}
})(jQuery);